import PropTypes from 'prop-types';

import Autocomplete from '@audiencetown/owk-uitk/dist/components/Autocomplete';
import TextField from '@audiencetown/owk-uitk/dist/components/TextField';
import InputAdornment from '@audiencetown/owk-uitk/dist/components/InputAdornment';

function Filter({
  value,
  items,
  onChange,
  disabled,
  label,
  disabledFilter,
  Icon,
}) {
  return (
    <Autocomplete
      style={{ width: '12.5rem' }}
      id={label}
      size="small"
      options={items || []}
      getOptionLabel={item => (item.name ? item.name : '')}
      getOptionSelected={(option, value) => option?.name === value?.name}
      onChange={onChange}
      value={value || ''}
      disabled={disabled || disabledFilter}
      renderInput={params =>
        Icon ? (
          <TextField
            {...params}
            placeholder={label}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Icon />
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <TextField {...params} placeholder={label} variant="outlined" />
        )
      }
    />
  );
}

Filter.defaultProps = {
  items: [],
  value: {},
  onchange: () => {},
  disabled: false,
  label: '',
  disabledFilter: false,
};
Filter.propTypes = {
  items: PropTypes.array,
  value: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  disabledFilter: PropTypes.bool,
};

export default Filter;
