import Button from '@audiencetown/owk-uitk/dist/components/Button';
import Tooltip from '@audiencetown/owk-uitk/dist/components/Tooltip';
import Box from '@audiencetown/owk-uitk/dist/components/Box';

const ControlButton = props =>
  props.tooltip ? (
    <Tooltip title={props.tooltip} arrow>
      <Box>
        <Button
          style={props.disabled ? { pointerEvents: 'none' } : {}}
          {...props}>
          {props.text}
        </Button>
      </Box>
    </Tooltip>
  ) : (
    <Button {...props}>{props.text}</Button>
  );

export default ControlButton;
