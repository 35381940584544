import SearchBox from '../components/SearchBox';
import PeriodRangeSelect from '../components/PeriodRangeSelect';
import Button from '../components/Button';
import Filter from '../components/filter';
import SmallIcon from '../../../smallIcon';

import Grid from '@audiencetown/owk-uitk/dist/components/Grid';

const renderControl = (component, disabled, index) => {
  switch (component.name) {
    case 'SearchBox':
      return <SearchBox key={index} {...component.props} disabled={disabled} />;
    case 'PeriodRangeSelect':
      return (
        <PeriodRangeSelect
          key={index}
          {...component.props}
          disabled={disabled}
        />
      );
    case 'Button':
      return (
        <Button
          key={index}
          {...component.props}
          disabled={disabled || component.props.disabled}
        />
      );
    case 'IconButton':
      return <SmallIcon key={index} {...component.props} disabled={disabled  || component.props.disabled} />;
    case 'Filter':
      return <Filter key={index} {...component.props} disabled={disabled  || component.props.disabled} />;
    default:
      return <></>;
  }
};

export const ActionPanelControlsReducer = (components, disabled) => {
  return components.map((component, index) => (
    <Grid item key={index}>
      {renderControl(component, disabled, index)}
    </Grid>
  ));
};
