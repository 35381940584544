import React from 'react';

import Skeleton from '@audiencetown/owk-uitk/dist/components/Skeleton';
import List from '@audiencetown/owk-uitk/dist/components/List';
import ListItem from '@audiencetown/owk-uitk/dist/components/ListItem';
import ListItemText from '@audiencetown/owk-uitk/dist/components/ListItemText';
import ListItemIcon from '@audiencetown/owk-uitk/dist/components/ListItemIcon';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  list: {
    '& .MuiListItemIcon-root': {
      minWidth: '2.25rem',
    },
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  skeleton: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const NavbarSkeleton = () => {
  const classes = useStyles();

  return (
    <List>
      {[...Array(5)].map((_, index) => (
        <ListItem className={classes.list} key={index}>
          <ListItemIcon>
            <Skeleton
              variant="circle"
              className={`${classes.skeleton} ${classes.icon}`}></Skeleton>
          </ListItemIcon>
          <ListItemText>
            <Skeleton variant="text" className={classes.skeleton}></Skeleton>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export default NavbarSkeleton;
