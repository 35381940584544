import axios from 'axios';

export const CampaignRequestService = {
  get: async id => axios.get(`campaign-request/${id}`).then(res => res.data),
  update: async (id, data) =>
    axios.put(`campaign-request/${id}`, data).then(res => res.data),
  patch: async (id, data) =>
    axios.patch(`campaign-request/${id}`, data).then(res => res.data),
};

export const CampaignRequestCreativeService = {
  put: async (id, data) =>
    axios.put(`campaign-request/${id}/creative/`, data).then(res => res.data),
  get: async id =>
    axios.get(`campaign-request/${id}/creative/`).then(res => res.data),
};

export const CampaignRequestTaskService = {
  get: id =>
    axios.get(`campaign-request/${id}/tasks/`).then(res => res.data?.rows),
};

export const CampaignRequestClientService = {
  get: id =>
    axios.get(`campaign-request/${id}/service/`).then(res => res.data?.rows),
  create: (id, data) =>
    axios.post(`campaign-request/${id}/service/`, data).then(res => res.data),
};

export const CampaignRequestAuditService = {
  get: (id, startDate, endDate) =>
    axios
      .get(`campaign-request/${id}/audit/`, {
        params: {
          startDate,
          endDate,
        },
      })
      .then(res => res.data),
};
