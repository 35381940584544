import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';
import Tooltip from '@audiencetown/owk-uitk/dist/components/Tooltip';
import { makeStyles } from '@material-ui/styles';

const useStyles = (data, value) =>
  makeStyles(theme => ({
    text: {
      minWidth: data[value].deleted || data[value].added ? '20rem' : '8rem',
      maxWidth: data[value].deleted || data[value].added ? '20rem' : '8rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: '0.1875rem',
    },
    lineThrough: {
      textDecoration: 'line-through',
    },
    typography: {
      marginTop: '0.1875rem',
    },
  }));

const RenderCreative = ({ data, value }) => {
  const classes = useStyles(data, value)();

  const dataToRender = (data, value) =>
    data?.[value]?.added || data?.[value]?.deleted
      ? data?.[value]?.added
        ? data?.[value]?.added
        : data?.[value]?.deleted
      : data?.[value]?.updated?.newValue;

  return (
    <>
      <Typography color="secondary">
        {value === 'clickThroughUrl'
          ? 'Click Through '
          : value === 'name'
          ? 'Name'
          : 'Landing Page'}
      </Typography>
      <Grid item container xs={12} spacing={6}>
        {data[value]?.updated?.oldValue ? (
          <Grid item xs={6}>
            <Tooltip title={data[value]?.updated?.oldValue}>
              <Typography
                color="secondary"
                className={`${classes.text} ${classes.lineThrough}`}>
                {data[value]?.updated?.oldValue}
              </Typography>
            </Tooltip>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={6}>
          <Tooltip title={dataToRender(data, value)}>
            <Typography
              color={
                data[value].added || data[value]?.updated?.newValue
                  ? 'primary'
                  : ''
              }
              className={`${classes.text} ${
                data[value].deleted ? classes.lineThrough : ''
              } ${classes.typography} `}>
              {dataToRender(data, value)}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};

export default RenderCreative;
