const getTenYears = () => {
  const years = [];
  const date = new Date();
  for (let i = 0; i < 10; i++) {
    years.push(date.getFullYear() + i);
  }
  return years;
};

export default getTenYears;
