import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import ListItem from '@audiencetown/owk-uitk/dist/components/ListItem';
import ListItemText from '@audiencetown/owk-uitk/dist/components/ListItemText';
import ListItemAvatar from '@audiencetown/owk-uitk/dist/components/ListItemAvatar';
import Avatar from '@audiencetown/owk-uitk/dist/components/Avatar';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';
import Link from '@audiencetown/owk-uitk/dist/components/Link';
import Box from '@audiencetown/owk-uitk/dist/components/Box';
import { TrendingUp } from '@audiencetown/owk-uitk/dist/icons';
import { getTimeInterval } from '../services/notifications.services';
import NotificationItemActions from './notificationItemAction';
import CustomBox from './customBox';
import { formatNumber } from '../../../helpers';

const useStyles = makeStyles(() => ({
  smallAvatar: {
    width: '1.5rem',
    height: '1.5rem',
  },
  itemAvatar: {
    minWidth: '2rem',
    marginTop: '0.375rem',
  },
  listItem: {
    paddingLeft: '0rem',
  },
  incompleteText: {
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
  },
  fullText: {
    'word-wrap': 'break-word',
    'overflow-wrap': 'break-word',
  },
}));
const CampaignSubscription = ({ onActionHandler, item, setIsPulled }) => {
  const navigate = useNavigate();
  const navigateTo = id => navigate(`/reports/performance/campaign/${id}`);
  const classes = useStyles();
  return (
    <ListItem
      className={classes.listItem}
      alignItems="flex-start"
      key={item.id}
    >
      <ListItemAvatar className={classes.itemAvatar}>
        {item.from ? (
          <Avatar
            className={classes.smallAvatar}
            src={item.rawData.user.img ? item.rawData.user.img : ''}
          />
        ) : (
          <TrendingUp />
        )}
      </ListItemAvatar>
      <ListItemText
        style={{ padding: '0px 6px' }}
        primary={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {item?.from ? item?.rawData?.user?.name : 'System'}
            <Typography variant="caption">
              {getTimeInterval(item.createdAt)}
            </Typography>
          </Box>
        }
        secondary={
          <Typography component="span" variant="subtitle2" className={classes.fullText}>
            <Link
              onClick={() => {
                navigateTo(item.rawData.campaignSubscription.campaign.id);
                setIsPulled(false);
              }}
              component="span"
              variant="subtitle2"
              color="primary"
            >
              {`${item.rawData.campaignSubscription.campaign.name}`} 's{' '}
            </Link>
            {`${item.rawData.campaignSubscription.dimension} is ${formatNumber(
              item.rawData.campaignSubscription.value / 100,
              {
                style: 'percent',
                maximumFractionDigits: 2,
              },
            )} ${item.rawData.campaignSubscription.trend} in the last ${
              item.rawData.campaignSubscription.period
            } days`}
            {item.rawData.dimension}
            {item.rawData.dimension && (
              <CustomBox item={item}>
                <Typography className={classes.incompleteText}>
                  {item.rawData.dimension}
                </Typography>
              </CustomBox>
            )}
          </Typography>
        }
      />
      <NotificationItemActions onActionHandler={onActionHandler} id={item.id} />
    </ListItem>
  );
};

export default CampaignSubscription;
