import React from 'react';
import PropTypes from 'prop-types';

import SearchTable from './SearchTable';

const TableToolbar = ({ handleSearch, disabled, searchParam }) => (
  <SearchTable
    handleSearch={handleSearch}
    disabled={disabled}
    searchParam={searchParam}
  />
);

TableToolbar.defaultProps = {
  handleSearch: () => {},
  disabled: true,
};

TableToolbar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default TableToolbar;
