const formatNumber = (num, option, country, round) =>
  new Intl.NumberFormat(
    country || 'en-US',
    round
      ? (9999999999 >= num && num >= 1000000000) ||
        (9999999 >= num && num >= 1000000) ||
        (9999 >= num && num >= 1000) ||
        num < 10
        ? { ...option, notation: 'compact', maximumFractionDigits: 2 }
        : { ...option, notation: 'compact', maximumSignificantDigits: 4 }
      : option || {},
  ).format(num || 0);

export default formatNumber;
