export default function validateCreditCard(cardNumber) {
  const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
  const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
  const americanExpressRegEx = /^(?:3[47][0-9]{13})$/;
  const discoverRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
  const dinersClubRegEx = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
  const JCBRegEx = /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/;

  return (
    visaRegEx.test(cardNumber) ||
    mastercardRegEx.test(cardNumber) ||
    americanExpressRegEx.test(cardNumber) ||
    discoverRegEx.test(cardNumber) ||
    JCBRegEx.test(cardNumber) ||
    dinersClubRegEx.test(cardNumber)
  );
}
