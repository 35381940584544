import { makeStyles } from '@material-ui/styles';
import Box from '@audiencetown/owk-uitk/dist/components/Box';
import Tooltip from '@audiencetown/owk-uitk/dist/components/Tooltip';

const useStyles = makeStyles(theme => ({
  boxBorder: {
    border: `0.1rem solid ${theme.palette.text.primary}`,
    padding: '0.45rem',
    marginTop: '0.3rem',
    width: '14rem',
  },
}));

const CustomBox = ({ item, children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.boxBorder} borderRadius={3}>
      <Tooltip
        title={item?.rawData?.task?.description}
        arrow>
        {children}
      </Tooltip>
    </Box>
  );
};

export default CustomBox;
