import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from '../publicRoutes';

function SwitchRoutes({ removeItems }) {
  return (
    <>
      <Routes>
        {routes
          .filter(item => removeItems.includes(item.name))
          .map((prop, key) => {
            if (prop.layout === 'public') {
              return (
                <Route
                  path={prop.path}
                  exact={prop.isExact}
                  key={key.toString()}
                  element={<prop.component />}
                />
              );
            }
            return null;
          })}
        <Route
          path="/"
          element={<Navigate replace to="/public/reports/audience/" />}
        />
      </Routes>
    </>
  );
}
function PublicLayout() {
  const removeItems = ['Audience Report'];

  return <SwitchRoutes removeItems={removeItems} />;
}

SwitchRoutes.propTypes = {
  isLoggedIn: PropTypes.bool,
  removeItems: PropTypes.arrayOf(PropTypes.any),
};

SwitchRoutes.defaultProps = {
  isLoggedIn: false,
  removeItems: [],
};

export default PublicLayout;