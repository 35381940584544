import axios from 'axios';

export const getNotifications = async (offset, limit) =>
  axios
    .get('/notification/', {
      params: {
        offset: offset,
        limit: limit,
        sortBy: 'date',
      },
    })
    .then(res => res.data);
