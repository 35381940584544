import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { formatFullDateTime } from '../../../helpers';
import { HistoryService } from '../../../services';

import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';
import ListItem from '@audiencetown/owk-uitk/dist/components/ListItem';
import ListItemText from '@audiencetown/owk-uitk/dist/components/ListItemText';
import ListItemAvatar from '@audiencetown/owk-uitk/dist/components/ListItemAvatar';
import Divider from '@audiencetown/owk-uitk/dist/components/Divider';
import ProfileImage from '@audiencetown/owk-uitk/dist/assets/profile.svg';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: theme.palette.secondary.gray,
  },
}));

const CampaignHistory = ({ id, datesFilters, handleLoadingChange }) => {
  const classes = useStyles();
  const [data, setData] = useState();

  useEffect(() => {
    const fetchHistory = async () => {
      setData([]);
      handleLoadingChange(true);
      setData(
        await HistoryService.getCampaignHubHistoryById(
          id,
          datesFilters[0],
          datesFilters[1],
        ),
      );
      handleLoadingChange(false);
    };
    fetchHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, datesFilters]);

  const renderChild = action => {
    switch (action?.action) {
      case 'CREATE_CREATIVE':
        return 'Creative created';
      case 'UPDATE_CREATIVE':
        return 'Creative Update  ';
      case 'DELETE_CREATIVE':
        return 'Creative Update  ';

      case 'CREATE_SERVICE_REQUEST':
        return 'Service request create ';
      case 'UPDATE_SERVICE_REQUEST':
        return 'Service request update ';
      case 'DELETE_SERVICE_REQUEST':
        return 'Service request  delete ';

      case 'CREATE_TASK':
        return 'Task create ';
      case 'DELETE_TASK':
        return 'Task delete ';
      case 'UPDATE_TASK':
        return 'Task update ';

      default:
        return <>{action?.action?.replace('_', ' ')?.toLowerCase()}</>;
    }
  };

  return (
    <div>
      {data &&
        data?.map(action => (
          <React.Fragment key={`ListItemKey_${action?.id}`}>
            <ListItem alignItems="center" justifyContent="center">
              <ListItemAvatar>
                <img
                  style={{ width: '2.5rem', height: '2.5rem' }}
                  src={ProfileImage}
                  alt="user profile"
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Grid container spacing={10}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">
                        {action?.metadata?.user?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption">
                        {formatFullDateTime(action?.createdAt)}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                secondary={
                  <Typography
                    variant="subtitle2"
                    style={{ textTransform: 'capitalize' }}>
                    {renderChild(action)}
                  </Typography>
                }
              />
            </ListItem>
            <Divider variant="middle" className={classes.divider} />
          </React.Fragment>
        ))}
    </div>
  );
};

CampaignHistory.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleLoadingChange: PropTypes.func.isRequired,
  datesFilters: PropTypes.array.isRequired,
};

export default CampaignHistory;
