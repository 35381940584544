import {
  getTitle,
  displayOptions,
  renderChild,
} from '../../helpers/publishedComponent';

import RenderCreative from './renderCreative';
import ListItemHistory from './listItem';

import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';
import ListItem from '@audiencetown/owk-uitk/dist/components/ListItem';
import ListItemText from '@audiencetown/owk-uitk/dist/components/ListItemText';
import Card from '@audiencetown/owk-uitk/dist/components/Card';
import CardContent from '@audiencetown/owk-uitk/dist/components/CardContent';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: theme.palette.secondary.gray,
    marginTop: '1rem',
  },
  dividerMargin: {
    marginTop: '1rem',
  },
  root: {
    marginLeft: '.5rem',
    marginRight: '1rem',
  },

  lineThrough: {
    textDecoration: 'line-through',
  },
  card: {
    marginLeft: '1.5rem',
  },
}));

const RenderItemsHistory = ({ data, value }) => {
  const classes = useStyles();

  return (
    <>
      {value === 'creativeDiff' ? (
        <>
          <>
            <ListItem alignItems="center" justifyContent="center">
              <ListItemText
                primary={
                  <Grid container xs={12} spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        {getTitle(value)}
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
          </>
          {data[value].map(el => (
            <Grid container direction="column" spacing={2}>
              <Grid item container className={classes.root} spacing={5}>
                <Grid item xs={11}>
                  {el?.name ? (
                    <Typography
                      color={el?.name?.added ? 'primary' : ''}
                      className={` ${
                        el?.name?.deleted ? classes.lineThrough : ''
                      }`}>
                      {el?.name?.added || el?.name?.deleted
                        ? el?.name?.added
                          ? el?.name?.added
                          : el?.name?.deleted
                        : el?.name?.updated?.newValue}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item xs={12}>
                  <Card className={`${classes.root} ${classes.card}`}>
                    <CardContent>
                      {el.name ? (
                        <>
                          <RenderCreative data={el} value={'name'} />
                        </>
                      ) : (
                        <></>
                      )}

                      {el?.landingPage ? (
                        <>
                          <br />
                          <RenderCreative data={el} value={'landingPage'} />
                        </>
                      ) : (
                        <></>
                      )}

                      {el?.clickThroughUrl ? (
                        <>
                          <br />
                          <RenderCreative data={el} value={'clickThroughUrl'} />
                        </>
                      ) : (
                        <></>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </>
      ) : (
        <></>
      )}
      {data[value] && displayOptions.includes(value) ? (
        value === 'flights' ? (
          <>
            <ListItem alignItems="center" justifyContent="center">
              <ListItemText
                primary={
                  <Grid container xs={12} spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        {getTitle(value)}
                      </Typography>
                    </Grid>
                    <Grid item container spacing={6}>
                      <Grid item xs={1}></Grid>
                      <Grid item container xs={11} spacing={2}>
                        {renderChild(data, value)}
                      </Grid>
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
          </>
        ) : value === 'primaryAudience' || value === 'secondaryAudience' ? (
          <>
            {data[value].hasOwnProperty('added') ||
            data[value].hasOwnProperty('deleted')
              ? Object.keys(data[value]).map(element =>
                  Object.keys(data[value][element]).map(el => (
                    <ListItemHistory
                      title={getTitle(el, value, data)}
                      renderChild={renderChild(
                        data[value][element],
                        el,
                        element,
                      )}
                    />
                  )),
                )
              : Object.keys(data[value]).map(el => (
                  <ListItemHistory
                    title={getTitle(el, value, data)}
                    renderChild={renderChild(data[value], el)}
                  />
                ))}
          </>
        ) : data[value].added ? (
          (value === 'channels' && data[value]?.added.length > 0) ||
          (value === 'outcome' && data[value]?.added?.values.length > 0) ||
          (data[value]?.added !== undefined &&
            value !== 'channels' &&
            value !== 'outcome') ? (
            <ListItemHistory
              title={getTitle(value)}
              renderChild={renderChild(data, value)}
            />
          ) : (
            <></>
          )
        ) : (
          <ListItemHistory
            title={getTitle(value)}
            renderChild={renderChild(data, value)}
          />
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default RenderItemsHistory;
