import { createContext, useContext, useState, useEffect } from 'react';

import { getAdvertiserBillingInfo } from './services/service.billing';
import { userContext } from './userContext';

export const AdvertiserBillingInfoContext = createContext();

export const AdvertiserBillingInfoProvider = ({ children }) => {
  const [inheritFromAgency, setInheritFromAgency] = useState(false);
  const { user } = useContext(userContext);
  const value = {
    inheritFromAgency,
    setInheritFromAgency,
  };
  useEffect(() => {
    const fetchAdvertiserBillingInfo = async () => {
      if (user.userData.scope.objectType === 'advertiser') {
        const advertiserBillingInfo = await getAdvertiserBillingInfo(
          user.userData.scope.objectId,
        );
        setInheritFromAgency(advertiserBillingInfo?.inherentFromAgency);
      }
      if (user.userData.scope.objectType === 'agency') {
        setInheritFromAgency(false);
      }
    };
    fetchAdvertiserBillingInfo();
  }, [user?.userData?.scope]);

  return (
    <AdvertiserBillingInfoContext.Provider value={value}>
      {children}
    </AdvertiserBillingInfoContext.Provider>
  );
};
