import axios from 'axios';
import Promise from 'bluebird';
import moment from 'moment';

export const getTimeInterval = date =>
  `${moment.duration(moment().diff(moment(Date.parse(date)))).humanize()} ago`;

export const readNotifications = async notifications =>
  Promise.map(notifications, async item =>
    axios
      .put(`/notification/${item.id}`, {
        isRead: true,
      })
      .then(res => res.data),
  );

export const deleteNotification = async id =>
  axios.delete(`/notification/?ids=[${id}]`).then(res => res.data);

export const unreadNotification = async id =>
  axios.put(`/notification/${id}`, {
    isRead: false,
  });
