import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { PerformanceDataGlobalFilterContext } from '../../../../performanceDataGlobalFilterContext';
import {
  supportedDateFilter,
  formatDate,
} from '../../../../helpers/constants.helper';

import MenuItem from '@audiencetown/owk-uitk/dist/components/MenuItem';
import InputAdornment from '@audiencetown/owk-uitk/dist/components/InputAdornment';
import TextField from '@audiencetown/owk-uitk/dist/components/TextField';
import { CalendarTodayOutlined } from '@audiencetown/owk-uitk/dist/icons';
import { RangeDatePicker } from '@audiencetown/owk-uitk/dist/components/DatePicker';

function DateRange({ className, disabled, isSupportDate }) {
  const { dateFilter, setDateFilter } = useContext(
    PerformanceDataGlobalFilterContext,
  );
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const handleOpenCalendar = () => {
    setIsOpenCalendar(true);
  };
  const handleCloseCalendar = () => {
    setIsOpenCalendar(false);
  };
  const handleTimeline = e => {
    const date = e.target.value;
    if (date !== 'custom')
      setDateFilter({
        ...dateFilter,
        timeline: date,
        customRange: [
          moment().subtract(date, 'day').format('YYYY-MM-DD'),
          moment().subtract(1, 'day').format('YYYY-MM-DD'),
        ],
      });
  };
  const calendarOnChange = (startDate, endDate) => {
    if (startDate && endDate)
      setDateFilter({
        ...dateFilter,
        timeline: 'custom',
        customRange: [
          formatDate(moment(startDate)),
          formatDate(moment(endDate)),
        ],
      });
  };

  const handleDate = date =>
    new Date(date?.year(), date?.month(), +date?.format('D'));

  useEffect(() => {
    if (isSupportDate && !supportedDateFilter.includes(dateFilter?.timeline)) {
      setDateFilter({
        ...dateFilter,
        timeline: 7,
        customRange: [
          formatDate(moment().subtract(7, 'day')),
          formatDate(moment().subtract(1, 'day')),
        ],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSupportDate, dateFilter]);

  return (
    <>
      <TextField
        size="small"
        select
        className={className}
        onChange={handleTimeline}
        value={dateFilter.timeline}
        variant="outlined"
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalendarTodayOutlined fontSize="small" />
            </InputAdornment>
          ),
        }}>
        <MenuItem value={1}>Last 1 Day</MenuItem>
        <MenuItem value={3} disabled={isSupportDate}>
          Last 3 Days
        </MenuItem>
        <MenuItem value={7}>Last 7 Days</MenuItem>
        <MenuItem value={14}>Last 14 Days</MenuItem>
        <MenuItem value={30}>Last 30 Days</MenuItem>
        <MenuItem value={90} disabled={isSupportDate}>
          Last 90 Days
        </MenuItem>
        <MenuItem value={180} disabled={isSupportDate}>
          Last 180 Days
        </MenuItem>
        <MenuItem value={365} disabled={isSupportDate}>
          Last 365 Days
        </MenuItem>
        <MenuItem
          value="custom"
          onClick={handleOpenCalendar}
          disabled={isSupportDate}>
          Custom
        </MenuItem>
      </TextField>
      <RangeDatePicker
        dateFormat="YYYY/MM/DD"
        onChange={calendarOnChange}
        isOpen={isOpenCalendar}
        onCloseCalendar={handleCloseCalendar}
        startDate={handleDate(moment(dateFilter.customRange[0]))}
        endDate={handleDate(moment(dateFilter.customRange[1]))}
        ignoreDefaultControls={true}
        maxDate={new Date()}
      />
    </>
  );
}

DateRange.defaultProps = {
  className: '',
  disabled: true,
};
DateRange.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DateRange;
