import axios from 'axios';

export const CreativeService = {
  upload: async (file, campaignRequest) => {
    const formData = new FormData();
    formData.append('file', file);

    const config = {
      headers: {
        'Content-Type': `multipart/form-data`,
        data: JSON.stringify({
          advertiserId: campaignRequest.advertiserId,
          agencyId: campaignRequest.agencyId,
          campaignRequestId: campaignRequest.id,
          metadata: {
            advertiserName: campaignRequest?.metadata?.advertiserName,
            agencyName: campaignRequest?.metadata?.agencyName,
          },
          name: file.name,
          type: file.type.split('/')[0],
          size: file.size,
          extension: file.type.split('/')[1],
          duration: file.duration,
          dimension: { width: file.width, height: file.height },
        }),
      },
    };
    return axios.post('creative/', formData, config).then(res => res.data);
  },
  get: async id => axios.get(`creative/${id}`).then(res => res.data),
  info: async id => axios.get(`creative/${id}/info`).then(res => res.data),
  patch: async (id, data) =>
    axios.patch(`creative/${id}`, data).then(res => res.data),
};
