const PROD = {
  apiBaseUrl: 'https://api.audiencetown.com/api/v2/',
  urlLogin: 'https://app.audiencetown.com/login',
  appBaseUrl: 'https://selfservice.audiencetown.com',
  domain: 'audiencetown.com',
  secure: true,
  imageFileSizeLimit: 500000, // 500 KB
  othersFileSizeLimit: 1073741824, // 1 GB
  fileAcceptValidation:
    '.jpeg,.jpg,.png,.gif,.mp4,.avi,.mov,.3pas,.wav,.ogg,.mp3',
};

const STAGING = {
  apiBaseUrl: 'https://api.staging.audiencetown.com/api/v2/',
  urlLogin: 'https://app.staging.audiencetown.com/login',
  appBaseUrl: 'https://selfservice.staging.audiencetown.com',
  domain: 'staging.audiencetown.com',
  secure: true,
  imageFileSizeLimit: 500000,
  othersFileSizeLimit: 1073741824,
  fileAcceptValidation:
    '.jpeg,.jpg,.png,.gif,.mp4,.avi,.mov,.3pas,.wav,.ogg,.mp3',
};
const DEV = {
  apiBaseUrl: process.env.REACT_APP_BASE_URL || STAGING.apiBaseUrl,
  urlLogin: `${process.env.REACT_APP_LOGIN_URL}/login` || STAGING.urlLogin,
  appBaseUrl: process.env.REACT_APP_APP_BASE_URL || STAGING.appBaseUrl,
  domain: false,
  secure: false,
  imageFileSizeLimit:
    process.env.REACT_APP_IMAGE_FILE_SIZE_LIMIT || STAGING.imageFileSizeLimit,
  othersFileSizeLimit:
    process.env.REACT_APP_OTHERS_FILE_SIZE_LIMIT || STAGING.othersFileSizeLimit,
  fileAcceptValidation:
    process.env.REACT_APP_FILE_ACCEPT_VALIDATION ||
    STAGING.fileAcceptValidation,
};

const config =
  process.env.REACT_APP_ENV === 'prod'
    ? PROD
    : process.env.REACT_APP_ENV === 'staging'
    ? STAGING
    : DEV;

export default config;
