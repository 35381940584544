import React from 'react';
import { makeStyles } from '@material-ui/styles';
import ListItem from '@audiencetown/owk-uitk/dist/components/ListItem';
import ListItemText from '@audiencetown/owk-uitk/dist/components/ListItemText';
import ListItemAvatar from '@audiencetown/owk-uitk/dist/components/ListItemAvatar';
import Avatar from '@audiencetown/owk-uitk/dist/components/Avatar';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';
import Link from '@audiencetown/owk-uitk/dist/components/Link';
import Box from '@audiencetown/owk-uitk/dist/components/Box';
import { getTimeInterval } from '../services/notifications.services';
import NotificationItemActions from './notificationItemAction';

const useStyles = makeStyles(() => ({
  smallAvatar: {
    width: '1.5rem',
    height: '1.5rem',
  },
  itemAvatar: {
    minWidth: '2rem',
    marginTop: '0.375rem',
  },
  listItem: {
    paddingLeft: '0rem',
  },
  incompleteText: {
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
  },
  fullText: {
    'word-wrap': 'break-word',
    'overflow-wrap': 'break-word',
  },
}));

const DeletedCampaignNotificationItem = ({
  onActionHandler,
  item,
  setIsPulled,
  text,
}) => {
  const classes = useStyles();
  return (
    <ListItem
      className={classes.listItem}
      alignItems="flex-start"
      key={item?.id}>
      <ListItemAvatar className={classes.itemAvatar}>
        <Avatar
          className={classes.smallAvatar}
          src={item?.rawData?.user?.img ? item.rawData.user.img : ''}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            {item?.from ? item?.rawData?.user?.name : 'System'}
            <Typography variant="caption">
              {getTimeInterval(item?.createdAt)}
            </Typography>
          </Box>
        }
        secondary={
          <Typography component="span" variant="subtitle2" className={classes.fullText}>
            {text}{' '}
            <Link
              onClick={() => {
                setIsPulled(false);
              }}
              component="span"
              variant="subtitle2"
              color="primary">
              {item?.rawData?.campaignRequest?.name}
            </Link>
          </Typography>
        }
      />
      <NotificationItemActions onActionHandler={onActionHandler} id={item.id} />
    </ListItem>
  );
};

export default DeletedCampaignNotificationItem;
