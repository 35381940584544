import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import ListItem from '@audiencetown/owk-uitk/dist/components/ListItem';
import ListItemText from '@audiencetown/owk-uitk/dist/components/ListItemText';
import ListItemAvatar from '@audiencetown/owk-uitk/dist/components/ListItemAvatar';
import Avatar from '@audiencetown/owk-uitk/dist/components/Avatar';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';
import Link from '@audiencetown/owk-uitk/dist/components/Link';
import { AnnouncementOutlined } from '@audiencetown/owk-uitk/dist/icons';
import CustomBox from './customBox';
import { getTimeInterval } from '../services/notifications.services';
import NotificationItemActions from './notificationItemAction';
import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
const useStyles = makeStyles(() => ({
  smallAvatar: {
    width: '1.5rem',
    height: '1.5rem',
  },
  itemAvatar: {
    minWidth: '2rem',
    marginTop: '0.375rem',
  },
  listItem: {
    paddingLeft: '0rem',
  },
  incompleteText: {
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
  },
  listItemText: {
    padding: '0rem 0.375rem',
  },
}));

const NewTaskNotificationItem = ({
  onActionHandler,
  item,
  setIsPulled,
  text,
}) => {
  const navigate = useNavigate();
  const navigateTo = id => navigate(`/campaigns/${id}`);
  const classes = useStyles();

  return (
    <ListItem
      className={classes.listItem}
      alignItems="flex-start"
      key={item.id}>
      <ListItemAvatar className={classes.itemAvatar}>
        {item?.from ? (
          <Avatar
            className={classes.smallAvatar}
            src={item?.rawData?.user?.img ? item?.rawData?.user?.img : ''}
          />
        ) : (
          <AnnouncementOutlined />
        )}
      </ListItemAvatar>
      <ListItemText
        className={classes.listItemText}
        primary={
          <Grid container>
            <Grid item xs={8}>
              <Typography>
                {item?.from ? item?.rawData?.user?.name : 'System'}
              </Typography>
            </Grid>
            <Grid item xs={4} align="right">
              <Typography variant="caption">
                {getTimeInterval(item?.createdAt)}
              </Typography>
            </Grid>
          </Grid>
        }
        secondary={
          <Typography component="span" variant="subtitle2">
            {text}
            <Link
              onClick={() => {
                navigateTo(item?.rawData?.campaignRequest?.id);
                setIsPulled(false);
              }}
              component="span"
              variant="subtitle2"
              color="primary">
              {` ${item?.rawData?.task?.title} `}
            </Link>
            {item?.rawData?.task?.description && (
              <CustomBox item={item}>
                <Typography className={classes.incompleteText}>
                  {item?.rawData?.task?.description}
                </Typography>
              </CustomBox>
            )}
          </Typography>
        }
      />
      <NotificationItemActions
        onActionHandler={onActionHandler}
        id={item?.id}
      />
    </ListItem>
  );
};

export default NewTaskNotificationItem;
