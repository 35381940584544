import moment from 'moment';

export const skeletonRender = new Array(5).fill();

export const isValidZipcode = zipcode =>
  !zipcode.match(/^$|^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/);

export const expiryMonth = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

export const months = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const noDataText = 'No matching data';

export const fullDateTimeFormat = 'MM/DD/YYYY hh:mm A';
export const dateFormat = 'MM/DD/YYYY';
export const shortFullDateFormat = 'MM/DD/YY';
export const shortDateFormat = 'MM/DD';
export const monthNameDayOfMonthYear = 'll';

const momentTime = (date, format) =>
  moment.utc(
    moment.utc(moment.isMoment(date) ? date.format(dateFormat) : date),
    format,
  );

export const defaultMomentTime = date => moment(date);

const formatDateString = (date, format, isDefaultTime) =>
  isDefaultTime
    ? defaultMomentTime(date).format(format)
    : momentTime(date).format(format);

export const formatDate = (date, isDefaultTime) =>
  formatDateString(date, dateFormat, isDefaultTime);

export const formatFullDateTime = date =>
  formatDateString(date, fullDateTimeFormat);

export const formatShortFullDate = date =>
  formatDateString(date, shortFullDateFormat);
export const formatShortDate = date => formatDateString(date, shortDateFormat);
export const toLongDateString = date => momentTime(date).format('MMMM D, YYYY');
export const MonthNameDayOfMonthYear = date =>
  momentTime(date).format(monthNameDayOfMonthYear);

export const parseDate = (date, format = dateFormat) =>
  momentTime(date, format);

export const dateParser = date => parseDate(date, 'YYYY-MM-DD');

export const budgetLimit = 9999999.99;

export const publishedOrderStatus = [
  'published',
  'live',
  'paused',
  'completed',
];

export const supportedDateFilter = [1, 7, 14, 30];

export const UNSUPPORTED_THEME_COLORS = {
  PINK: '#FF4598',
  YELLOW: '#FCF26E',
  BLUE: '#018BFF',
  LIGHT_YELLOW: '#FFFCB4',
  GRAY: '#e1e0e0',
};

export const USA_STATES_LIST = [
  { name: 'Alabama', key: 'AL' },
  { name: 'Alaska', key: 'AK' },
  { name: 'Arizona', key: 'AZ' },
  { name: 'Arkansas', key: 'AR' },
  { name: 'California', key: 'CA' },
  { name: 'Colorado', key: 'CO' },
  { name: 'Connecticut', key: 'CT' },
  { name: 'Delaware', key: 'DE' },
  { name: 'Florida', key: 'FL' },
  { name: 'Georgia', key: 'GA' },
  { name: 'Hawaii', key: 'HI' },
  { name: 'Idaho', key: 'ID' },
  { name: 'Illinois', key: 'IL' },
  { name: 'Indiana', key: 'IN' },
  { name: 'Iowa', key: 'IA' },
  { name: 'Kansas', key: 'KS' },
  { name: 'Kentucky', key: 'KY' },
  { name: 'Louisiana', key: 'LA' },
  { name: 'Maine', key: 'ME' },
  { name: 'Maryland', key: 'MD' },
  { name: 'Massachusetts', key: 'MA' },
  { name: 'Michigan', key: 'MI' },
  { name: 'Minnesota', key: 'MN' },
  { name: 'Mississippi', key: 'MS' },
  { name: 'Missouri', key: 'MO' },
  { name: 'Montana', key: 'MT' },
  { name: 'Nebraska', key: 'NE' },
  { name: 'Nevada', key: 'NV' },
  { name: 'New Hampshire', key: 'NH' },
  { name: 'New Jersey', key: 'NJ' },
  { name: 'New Mexico', key: 'NM' },
  { name: 'New York', key: 'NY' },
  { name: 'North Carolina', key: 'NC' },
  { name: 'North Dakota', key: 'ND' },
  { name: 'Ohio', key: 'OH' },
  { name: 'Oklahoma', key: 'OK' },
  { name: 'Oregon', key: 'OR' },
  { name: 'Pennsylvania', key: 'PA' },
  { name: 'Rhode Island', key: 'RI' },
  { name: 'South Carolina', key: 'SC' },
  { name: 'South Dakota', key: 'SD' },
  { name: 'Tennessee', key: 'TN' },
  { name: 'Texas', key: 'TX' },
  { name: 'Utah', key: 'UT' },
  { name: 'Vermont', key: 'VT' },
  { name: 'Virginia', key: 'VA' },
  { name: 'Washington', key: 'WA' },
  { name: 'West Virginia', key: 'WV' },
  { name: 'Wisconsin', key: 'WI' },
  { name: 'Wyoming', key: 'WY' },
];
