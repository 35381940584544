import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { userContext } from '../userContext';
import config from '../config';
import { getNotifications } from '../services/notification.service';
import { readNotifications } from '../views/Notifications/services/notifications.services';
import { BreadcrumbsContext } from '../breadcrumbsContext';
import { NotificationsContext } from '../notificationContext';
import Notifications from '../views/Notifications/notifications';
import { getAdvertiserBillingInfo } from '../services';

import ListItemText from '@audiencetown/owk-uitk/dist/components/ListItemText';
import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Badge from '@audiencetown/owk-uitk/dist/components/Badge';
import ListItemIcon from '@audiencetown/owk-uitk/dist/components/ListItemIcon';
import Navbar from '@audiencetown/owk-uitk/dist/components/Navbar';
import NavbarItem from '@audiencetown/owk-uitk/dist/components/NavbarItem';
import Slide from '@audiencetown/owk-uitk/dist/components/Slide';
import NavbarSkeleton from './navbarSkeleton';
import List from '@audiencetown/owk-uitk/dist/components/List';
import Drawer from '@audiencetown/owk-uitk/dist/components/Drawer';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';
import {
  DashboardOutlined,
  AssignmentOutlined,
  FlagOutlined,
  NotificationsOutlined,
  ExitToAppOutlined,
  SettingsOutlined,
  PersonOutline,
  ReceiptOutlined,
  BarChartOutlined,
  ImageOutlined,
} from '@audiencetown/owk-uitk/dist/icons';
import Box from '@audiencetown/owk-uitk/dist/components/Box';
import { ReactComponent as ReportIcon } from '../images/icons/reportIcon.svg';
import SvgIcon from '@audiencetown/owk-uitk/dist/components/SvgIcon';

const SideNavbar = () => {
  const sideMenu = [
    {
      name: 'Home',
      icon: <DashboardOutlined />,
      path: '/dashboard',
    },
    {
      name: 'Performance',
      icon: <BarChartOutlined />,
      path: '/reports/performance',
    },
  ];

  const navigate = useNavigate();
  const cookie = new Cookies();
  const { user } = useContext(userContext);
  const [isOpen, setIsOpen] = useState(true);
  const [isPulled, setIsPulled] = useState(false);
  const [notificationsLoading, setNotificationsLoading] = useState(true);
  const [unreadCount, setUnreadCount] = useState(0);
  const {
    updateNotificationContext: updateNotification,
    setUpdateNotificationContext: setUpdateNotification,
  } = useContext(NotificationsContext);
  const [notifications, setNotifications] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [noMoreNotifications, setNoMoreNotifications] = useState(false);
  const [inheritFromAgency, setInheritFromAgency] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user.userData.type === 'advertiser') {
      const billingData = async () => {
        try {
          const inheritData = await getAdvertiserBillingInfo(
            user.userData.scope.objectId,
          );
          setInheritFromAgency(inheritData?.inherentFromAgency);
        } catch (e) {
          setInheritFromAgency(true);
        } finally {
          setIsLoading(false);
        }
      };
      billingData();
    } else {
      setInheritFromAgency(false);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      setNotificationsLoading(true);
      const { notifications, unRead } = await getNotifications(0, 100);
      if (notifications.length < 100) setNoMoreNotifications(true);
      setUnreadCount(unRead.count);
      setNotifications(notifications);
      setNotificationsLoading(false);
      setOffset(0);
      setNoMoreNotifications(false);
    };
    fetchNotifications();
  }, [updateNotification, isPulled]);

  if (
    user.userData.scope.objectType === 'agency' &&
    !sideMenu.some(side => side.name === 'Advertisers')
  ) {
    sideMenu.push({
      name: 'Advertisers',
      icon: <AssignmentOutlined />,
      path: '/advertisers',
    });
  }

  if (!sideMenu.some(side => side.name === 'Campaign Requests')) {
    sideMenu.push({
      name: 'Campaigns',
      icon: <FlagOutlined />,
      path: '/campaigns',
    });
  }

  if (!sideMenu.some(side => side.name === 'Reports') && process.env.REACT_APP_ENV !== 'prod' ) {
    sideMenu.push({
      name: 'Reports',
      icon: <SvgIcon component={ReportIcon} />,
      path: '/reports',
    });
  }

  if (!sideMenu.some(side => side.name === 'Creative Hub')) {
    sideMenu.push({
      name: 'Creative Hub',
      icon: <ImageOutlined />,
      path: '/creatives',
    });
  }

  if (
    !sideMenu.some(side => side.name === 'Invoicing') &&
    user.userData.roleId !== 9 &&
    user.userData.roleId !== 7 &&
    !inheritFromAgency
  ) {
    sideMenu.push({
      name: 'Invoicing',
      icon: <ReceiptOutlined />,
      path: '/invoicing',
    });
  }

  if (
    user.userData.permissions.includes(2) &&
    !sideMenu.some(side => side.name === 'Users')
  ) {
    sideMenu.push({
      name: 'Users',
      icon: <PersonOutline />,
      path: '/settings/users',
    });
  }

  if (!sideMenu.some(side => side.name === 'Settings')) {
    sideMenu.push({
      name: 'Settings',
      icon: <SettingsOutlined />,
      path: '/settings',
    });
  }

  const { breadcrumbsItems, setBreadcrumbsItems } =
    useContext(BreadcrumbsContext);
  const { navSelected, setNavSelected } = useContext(BreadcrumbsContext);

  const { domain, secure, urlLogin } = config;
  function handleLogout() {
    cookie.remove('at-auth', {
      path: '/',
      domain,
      secure,
    });
    window.location.href = urlLogin;
  }

  useEffect(() => {
    setNavSelected(
      sideMenu.filter(route =>
        window.location.pathname.includes(route?.path),
      )[0]?.name,
    );
  });

  const readNotificationsHandler = () =>
    readNotifications(notifications.filter(item => !item.isRead));

  const loadMoreHandler = async () => {
    setLoadMoreLoading(true);
    const data = await getNotifications(offset + 100, 100);
    setOffset(offset + 100);
    setUnreadCount(data.unRead.count);
    readNotifications(data.notifications.filter(item => !item.isRead));
    if (data.notifications.length < 100) setNoMoreNotifications(true);
    setNotifications([...notifications, ...data.notifications]);
    setLoadMoreLoading(false);
  };

  const editNotificationsHandler = notifications =>
    setNotifications(notifications);
  return (
    <>
      <Navbar
        name={
          <Typography style={{ maxWidth: 180 }} noWrap={true}>
            {user.userData.name}
          </Typography>
        }
        onSettingsClick={() => {
          setNavSelected('My Profile');
          navigate(`/profile`);
        }}
        onOpenStatusChanged={({ open }) => {
          setIsOpen(open);
        }}>
        {isLoading ? (
          <NavbarSkeleton />
        ) : (
          <Slide
            direction="right"
            in={true}
            style={{ transitionDelay: '150ms' }}>
            <List component="nav" aria-label="menu" style={{ height: '0rem' }}>
              {sideMenu.map((item, index) => (
                <NavbarItem
                  data-testid={item.name}
                  selected={item.name === navSelected}
                  onClick={() => {
                    setNavSelected(item.name);
                    navigate(item.path);
                    if (breadcrumbsItems?.slice(0, 1)?.length === 2)
                      setBreadcrumbsItems(breadcrumbsItems.slice(0, 1));
                  }}
                  key={index}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  {isOpen ? <ListItemText>{item.name}</ListItemText> : <></>}
                </NavbarItem>
              ))}
              <NavbarItem
                onClick={() => {
                  setIsPulled(true);
                  readNotificationsHandler();
                }}
                key="altMenu">
                {isOpen ? (
                  <>
                    <ListItemIcon>
                      <NotificationsOutlined />
                    </ListItemIcon>
                    <ListItemText>
                      <Box
                        style={{ padding: '0.125rem', marginTop: '0.25rem' }}>
                        <Grid
                          spacing={0}
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center">
                          Notifications
                          {unreadCount > 0 ? (
                            <Badge
                              color="primary"
                              badgeContent={unreadCount}
                              max={99}></Badge>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Box>
                    </ListItemText>
                  </>
                ) : (
                  <ListItemIcon>
                    {unreadCount > 0 ? (
                      <Badge
                        color="primary"
                        badgeContent={unreadCount}
                        max={99}>
                        <NotificationsOutlined />
                      </Badge>
                    ) : (
                      <NotificationsOutlined />
                    )}
                  </ListItemIcon>
                )}
              </NavbarItem>
              <NavbarItem
                data-testid='logout'
                title="Logout"
                onClick={() => handleLogout()}
                key="logout">
                <ListItemIcon>
                  <ExitToAppOutlined />
                </ListItemIcon>
                {isOpen ? <ListItemText>Logout</ListItemText> : ''}
              </NavbarItem>
            </List>
          </Slide>
        )}
      </Navbar>
      <Drawer
        anchor="left"
        color="secondary"
        open={isPulled}
        onClose={async () => {
          setIsPulled(false);
          setUpdateNotification(!updateNotification);
        }}>
        <Notifications
          setIsPulled={setIsPulled}
          notifications={notifications}
          editNotificationsHandler={editNotificationsHandler}
          loading={notificationsLoading}
          readNotifications={readNotificationsHandler}
          loadMoreHandler={loadMoreHandler}
          loadMoreLoading={loadMoreLoading}
          noMoreNotifications={noMoreNotifications}
        />
      </Drawer>
    </>
  );
};

export default SideNavbar;
