import { lazy } from 'react';
import { Assignment } from '@audiencetown/owk-uitk/dist/icons';

const Dashboard = lazy(() => import('./views/Dashboard/components/dashboard'));
const ComingSoon = lazy(() => import('./components/ComingSoonPage'));
const CreateCampaign = lazy(() =>
  import('./views/campaign request/createCampaign'),
);

const AdvertisersRouter = lazy(() =>
  import('./views/Manage Advertisers/Advertiser Info/routers'),
);
const InvoicingRouter = lazy(() => import('./views/Invoicing/router'));

const ReportsRouter = lazy(() => import('./views/Reports/router'));
const EditProfile = lazy(() =>
  import('./views/User Profile/components/editProfile'),
);

const AdvertiserUsersListing = lazy(() => import('./views/Users/UsersList'));
const NotFoundPage = lazy(() => import('./components/NotFoundPage'));

const Settings = lazy(() => import('./views/Settings/routers'));

const CampaignHubRouter = lazy(() => import('./views/Campaign hub/router'));

const CreativeHubRouter = lazy(() => import('./views/Creative hub/router'));

const dashboardRoutes = [
  {
    path: '/dashboard/*',
    name: 'Dashboard',
    component: Dashboard,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/advertisers/*',
    name: 'Advertisers List',
    component: AdvertisersRouter,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/settings/users/*',
    name: 'Users',
    component: AdvertiserUsersListing,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/settings/*',
    name: 'Settings',
    component: Settings,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/reports/*',
    name: 'reports',
    icon: Assignment,
    component: ReportsRouter,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/invoicing/*',
    name: 'Invoicing',
    icon: Assignment,
    component: InvoicingRouter,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/campaigns/new/*',
    name: 'Create Campaign',
    component: CreateCampaign,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/campaigns/*',
    name: 'Campaign Hub',
    component: CampaignHubRouter,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/creatives/*',
    name: 'Creative Hub',
    component: CreativeHubRouter,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/comingsoon/*',
    name: 'coming soon',
    component: ComingSoon,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/profile/*',
    name: 'Edit Profile',
    component: EditProfile,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/*',
    name: 'Not Found',
    component: NotFoundPage,
    layout: 'main',
    sidebar: false,
  },
];

export default dashboardRoutes;
