import { lazy } from 'react';

 const PublicAudienceReport = lazy(() =>
   import(
     '../src/views/_public/reports/audience/AudienceReport'
   ),
 );

 const PublicRoutes = [
   {
     path: '/public/reports/audience/:id',
     name: 'Audience Report',
     component: PublicAudienceReport,
     layout: 'public',
     sidebar: false,
   },
 ];

 export default PublicRoutes;
 