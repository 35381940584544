import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  text: {
    maxWidth: '100%',
    wordWrap: 'break-word',
  },
}));

const MoversTitle = ({ element }) => {
  const classes = useStyles();

  return element === 'slider' ? (
    <Grid item>
      <Typography variant="subtitle2" className={classes.text}>
        {`Slider: `}
      </Typography>
    </Grid>
  ) : element === 'moverPersonas' ? (
    <Grid item>
      <Typography variant="subtitle2" className={classes.text}>
        {`MoverPersonas: `}
      </Typography>
    </Grid>
  ) : (
    <Grid item>
      <Typography variant="subtitle2" className={classes.text}>
        {`Crm: `}
      </Typography>
    </Grid>
  );
};

export default MoversTitle;
