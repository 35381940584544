const isEmpty = obj => Object.keys(obj).length === 0;
const isObject = obj => obj != null && typeof obj === 'object';
const hasOwnProperty = (obj, ...args) => {
  return Object.prototype.hasOwnProperty.call(obj, ...args);
};
const isEmptyObject = obj => isObject(obj) && isEmpty(obj);

const diffArray = (oldValue, newValue) => {
  const newValues = newValue
    ?.map(element => JSON.stringify(element))
    .filter(
      element => !oldValue?.map(el => JSON.stringify(el)).includes(element),
    )
    ?.map(el => JSON.parse(el));

  const oldValues = oldValue
    ?.map(element => JSON.stringify(element))
    .filter(
      element => !newValue.map(el => JSON.stringify(el)).includes(element),
    )
    ?.map(el => JSON.parse(el));

  const noChange = oldValue
    ?.map(element => JSON.stringify(element))
    .filter(element => newValue.map(el => JSON.stringify(el)).includes(element))
    ?.map(el => JSON.parse(el));

  if (newValues.length === 0 && oldValues.length === 0) {
    return {};
  }

  return { deleted: oldValues, noChange: noChange, added: newValues };
};

export const objectComparer = (oldValue, newValue) => {
  if (oldValue === newValue) return {};

  if (Array.isArray(newValue)) {
    return diffArray(oldValue, newValue);
  }

  if (!isObject(oldValue) || !isObject(newValue))
    return { updated: { oldValue: oldValue, newValue: newValue } };

  const deletedValues = Object.keys(oldValue).reduce((acc, key) => {
    if (!hasOwnProperty(newValue, key)) {
      acc[key] = { deleted: oldValue[key] };
    }

    return acc;
  }, {});

  return Object.keys(newValue).reduce((acc, key) => {
    if (!hasOwnProperty(oldValue, key)) {
      acc[key] = { added: newValue[key] };
      return acc;
    }

    const difference = objectComparer(oldValue[key], newValue[key]);

    if (isEmptyObject(difference)) return acc;
    acc[key] = difference;
    return acc;
  }, deletedValues);
};
