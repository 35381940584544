import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Code } from '@material-ui/icons';
import ListItem from '@audiencetown/owk-uitk/dist/components/ListItem';
import ListItemText from '@audiencetown/owk-uitk/dist/components/ListItemText';
import ListItemAvatar from '@audiencetown/owk-uitk/dist/components/ListItemAvatar';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';
import Link from '@audiencetown/owk-uitk/dist/components/Link';
import Box from '@audiencetown/owk-uitk/dist/components/Box';
import { getTimeInterval } from '../services/notifications.services';
import NotificationItemActions from './notificationItemAction';


const useStyles = makeStyles(theme => ({
  listItem: {
    paddingLeft: '0rem',
  },
  itemAvatar: {
    minWidth: '2rem',
    marginTop: '0.375rem',
  },
  incompleteText: {
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
  },
}));

const PixelNotificationItem = ({ onActionHandler, item, setIsPulled }) => {
  const navigate = useNavigate();
  const navigateTo = () => navigate(`/settings/pixels`);

  const classes = useStyles();

  return (
    <ListItem
      className={classes.listItem}
      alignItems="flex-start"
      key={item.id}>
      <ListItemAvatar className={classes.itemAvatar}>
        <Code />
      </ListItemAvatar>
      <ListItemText
        style={{ padding: '0px 6px' }}
        primary={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            {item.from ? item.rawData.user.name : 'System'}
            <Typography variant="caption">
              {getTimeInterval(item.createdAt)}
            </Typography>
          </Box>
        }
        secondary={
          <Typography component="span" variant="subtitle2" style={{ wordWrap: "break-word" }}>
            {item?.rawData?.pixel?.isVerified
              ? 'Pixel Verified'
              : 'Pixel Unverified'}{' '}
            <Link
              onClick={() => {
                navigateTo();
                setIsPulled(false);
              }}
              component="span"
              variant="subtitle2"
              color="primary">
              {item?.rawData?.pixel?.name}
            </Link>
          </Typography>
        }
      />
      <NotificationItemActions onActionHandler={onActionHandler} id={item.id} />
    </ListItem>
  );
};

export default PixelNotificationItem;
