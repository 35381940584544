import React, { Suspense, useState, useEffect, useMemo, useRef } from 'react';
import { useLocation, useMatch } from 'react-router-dom';

import moment from 'moment';
import Cookies from 'universal-cookie';
import jwt from 'jsonwebtoken';
import axios from 'axios';

import config from '../config';
import { refreshTokenApi } from '../services/service.auth';
import { userContext } from '../userContext';
import { BreadcrumbsProvider } from '../breadcrumbsContext';
import { ActionPanelProvider } from '../actionPanelContext';
import { CustomHeaderProvider } from '../CustomHeaderContext';
import { NotificationsProvider } from '../notificationContext';
import { PerformanceDataGlobalFilterProvider } from '../performanceDataGlobalFilterContext';
import { HistoryProvider } from '../historyContext';

import Main from './Main';
import PublicLayout from './Public';
import publicRoutes from '../publicRoutes';

import Navbar from '../components/SideNavbar';
import HistoryDrawer from '../components/history/HistoryDrawer';
import { AdvertiserBillingInfoProvider } from '../advertiserBillingInfoContext';

import Content from '@audiencetown/owk-uitk/dist/components/Content';
import CircularProgress from '@audiencetown/owk-uitk/dist/components/CircularProgress';

const isPublicRoute = path =>
  !!publicRoutes.find(route => useMatch(route.path));

function AppContainer() {
  const location = useLocation();
  const isPublicPage =
    process.env.REACT_APP_ENV !== 'prod'
      ? isPublicRoute(location.pathname)
      : false;
  const style = Object.freeze({
    alignItems: 'center',
    height: '100vh',
    justifyContent: 'center',
    display: 'flex',
    alignItem: 'center',
  });

  const componentRef = useRef(null);

  useEffect(() => {
    componentRef?.current?.scrollIntoView();
  }, [location]);

  const [user, setUser] = useState({ loggedIn: false });
  const [name, setUserContextName] = useState('');
  const cookie = useMemo(() => new Cookies(), []);

  useEffect(() => {
    if (!isPublicPage) {
      const { token, refreshToken, timer } = cookie.get('at-auth') || '';
      const decode = jwt.decode(token);

      if (!decode || decode.type === 'admin') {
        // logout
        setUser({ loggedIn: false });
        cookie.remove('at-auth', {
          path: '/',
          domain: config.domain,
          secure: config.secure,
        });
        window.location.href = `${
          config.urlLogin
        }?returnUrl=${encodeURIComponent(
          window.location.href.replace(window.location.origin, ''),
        )}`;
      }
      // login
      if (token) {
        setUser({ loggedIn: true, userData: { ...decode, token } });
        axios.interceptors.request.use(async req => {
          const { token, refreshToken, timer } = cookie.get('at-auth') || '';

          if (token) {
            if (req.url !== 'auth/token/refresh') {
              if (moment().isAfter(timer)) {
                await refreshTokenApi(refreshToken, token, setUser);
              }
            }
            req.headers.Authorization = `Bearer ${token}`;
            return req;
          } else {
            window.location.href = `${
              config.urlLogin
            }?returnUrl=${encodeURIComponent(
              window.location.href.replace(window.location.origin, ''),
            )}`;
          }
        });
      } else {
        window.location.href = `${
          config.urlLogin
        }?returnUrl=${encodeURIComponent(
          window.location.href.replace(window.location.origin, ''),
        )}`;
      }
      if (moment().isAfter(timer)) {
        refreshTokenApi(refreshToken, token, setUser);
      }
    }
  }, [user.loggedIn, cookie, isPublicPage]);

  useEffect(() => {
    if (name) {
      setUser({ loggedIn: true, userData: { ...user.userData, name } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);
  const value = {
    user,
    setUserContextName,
  };

  return isPublicPage ? (
    <Suspense
      fallback={
        <div style={style}>
          <CircularProgress />
        </div>
      }>
      <PublicLayout componentRef={componentRef} />
    </Suspense>
  ) : user.loggedIn ? (
    <userContext.Provider value={value}>
      <HistoryProvider>
        <PerformanceDataGlobalFilterProvider>
          <BreadcrumbsProvider>
            <NotificationsProvider>
              <AdvertiserBillingInfoProvider>
                <Navbar />
                <HistoryDrawer />
                <ActionPanelProvider>
                  <CustomHeaderProvider>
                    <Content componentRef={componentRef}>
                      <Suspense
                        fallback={
                          <div style={style}>
                            <CircularProgress />
                          </div>
                        }>
                        <Main />
                      </Suspense>
                    </Content>
                  </CustomHeaderProvider>
                </ActionPanelProvider>
              </AdvertiserBillingInfoProvider>
            </NotificationsProvider>
          </BreadcrumbsProvider>
        </PerformanceDataGlobalFilterProvider>
      </HistoryProvider>
    </userContext.Provider>
  ) : (
    <></>
  );
}

export default AppContainer;
