import React, { useState, createContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const BreadcrumbsContext = createContext();

export const BreadcrumbsProvider = props => {
  const navigate = useNavigate();
  const [breadcrumbsItems, setBreadcrumbsItems] = useState([
    {
      key: '1',
      text: 'Home',
      pathname: '/',
      onClick: () => {
        navigate('/dashboard');
      },
    },
  ]);
  const [navSelected, setNavSelected] = useState('Home');
  const value = {
    navSelected,
    setNavSelected,
    breadcrumbsItems,
    setBreadcrumbsItems,
  };

  return (
    <BreadcrumbsContext.Provider value={value}>
      {props.children}
    </BreadcrumbsContext.Provider>
  );
};
